import { Button } from '@material-tailwind/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Start from './Start.jsx';
import { RedirectToSignIn, RedirectToSignUp, useAuth, useUser } from '@clerk/clerk-react';
import { ArrowRight } from 'react-feather';
import Loading from '../Components/Loading.jsx';
import { AxiosProvider, useAxios } from '../Components/AxiosProvider.jsx';

// const VideoPlayer = lazy(() => import('../Components/VideoPlayer.jsx'));

function HomeContent() {
  const { user } = useUser();
  const navigate = useNavigate();

  const axios = useAxios();
  const [redirectToSignUp, setRedirectToSignUp] = useState(false);
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchReportId = useCallback(async () => {
    if (!axios) return;
    setLoading(true);
    try {
      const response = await axios.get('calls/reports', {
        params: {
          limit: 1,
          offset: 0,
          reportType: 'INTAKE',
        },
      });
      const id = response?.data?.data[0]?.id;
      // Navigate based on report existence
      if (id) {
        navigate('/auth/dashboard');
      } else {
        navigate('/auth/report');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching report ID:', error);
    } finally {
      setLoading(false);
    }
  }, [axios, navigate]);

  useEffect(() => {
    if (user) {
      fetchReportId();
    } else {
      // If no user, stay on home page to show sign up/sign in options
      setLoading(false);
    }
  }, [user, fetchReportId]);

  // Handle redirection
  if (redirectToSignUp) {
    return <RedirectToSignUp />;
  }
  if (redirectToSignIn) {
    return <RedirectToSignIn />;
  }

  if (loading) {
    return <Loading />;
  }

  const handleSignUp = () => {
    setLoading(true);
    setRedirectToSignUp(true);
  };

  const handleSignIn = () => {
    setLoading(true);
    setRedirectToSignIn(true);
  };

  return (
    <div className="p-6 sm:p-8">
      {/* <div className="mb-8 rounded-xl overflow-hidden shadow-lg border-4 border-[#A173BF]">
        <Suspense fallback={<div>Loading video...</div>}>
          <VideoPlayer src="https://player.vimeo.com/video/1005533343?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Hana Introduction" />
        </Suspense>
      </div> */}

      <p className="text-black mb-8 text-lg text-center">
        Discover insights about your health with Hana, an AI voice agent designed to partner with you on your healthcare journey.
      </p>

      <div className="grid gap-6 sm:grid-row-3">
        <div className="bg-[#8DE8F2] p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
          <h2 className="text-xl font-semibold text-black mb-3">1. Have a Conversation</h2>
          <p className="text-black">Talk to Hana about your life and health - recent changes, sleeping patterns, energy levels, etc.</p>
        </div>

        <div className="bg-[#A173BF] p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
          <h2 className="text-xl font-semibold text-black mb-3">2. Discover Insights</h2>
          <p className="text-black">
            Hana analyzes your responses and creates a comprehensive health report, including recommendations on activities to address any identified health
            concerns.
          </p>
        </div>

        <div className="bg-[#D98032] p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
          <h2 className="text-xl font-semibold text-black mb-3">3. Schedule Follow-ups</h2>
          <p className="text-black">
            Schedule recurring calls to check in with Hana on your progress. The more you talk to Hana, the more personalized your experience can be.
          </p>
        </div>
      </div>
      {/* <Start /> */}
      <div className="flex justify-between items-center mt-5 sm:flex-row flex-col md:flex-row lg:flex-row">
        <Button fullWidth type="button" size="md" className="flex-1 mr-2 flex justify-center items-center" onClick={handleSignUp}>
          Begin Your Journey
          <ArrowRight className="ml-2" width={24} />
        </Button>
        <Button fullWidth size="md" className="flex-1 ml-2 text-black bg-white border border-gray-300" onClick={handleSignIn}>
          Already Have an Account?
        </Button>
      </div>
    </div>
  );
}

const Home = () => {
  const [token, setToken] = useState(null);
  const { getToken } = useAuth();

  useEffect(() => {
    getToken().then((t) => setToken(t));
  }, [getToken]);

  return (
    <AxiosProvider jwtToken={token}>
      <HomeContent />
    </AxiosProvider>
  );
};

export default Home;
