import { createBrowserRouter, Outlet, RouterProvider, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { ClerkProvider, SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import Home from './Pages/Home.jsx';
import Start from './Pages/Start.jsx';
import Call from './Pages/Call.jsx';
import Report from './Pages/Report.jsx';
import AuthedLayout from './AuthedLayout.jsx';
import SignIn from './Pages/SignIn.jsx';
import Login from './Pages/Login.jsx';
import DashboardContainer from './Dashboard/Container.jsx';
import Dashboard from './NewDashboard/index.jsx';
import VerifyEmail from './Components/verifyEmail.jsx';
import Followup from './Pages/Followup.jsx';
import RedirectToCall from './Components/RedirectToCall.jsx';

const Container = () => {
  return (
    <div className="min-h-screen bg-[#E8E7E7] p-4 sm:p-8">
      <main className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="bg-gradient-to-r from-[#A4D4EA] via-[#BED1F9] via-[#DEE2ED] via-[#FAF0E6] to-[#FDF6E3] flex p-4">
          <img src="/logo.png" alt="Hana Logo" className="h-10 mr-4" aria-label="Hana Logo" />
          <h1 className="text-3xl font-bold">Your Health Companion</h1>
          <div className="flex flex-grow justify-end">
            <SignedIn>
              <UserButton />
            </SignedIn>
          </div>
        </div>
        <SignedOut>
          <Navigate to={'/'} replace />
        </SignedOut>
        <Outlet />
      </main>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <DashboardContainer />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      {
        path: 'report/:reportId',
        element: <Report />,
      },
      {
        path: 'followup',
        element: <Followup />,
      },
    ],
  },
  {
    path: '/',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/start',
        element: <Start />,
      },
      {
        path: '/verify-email',
        element: <VerifyEmail />,
      },
      {
        path: '/report/:reportId',
        element: <Report />,
      },
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        element: <AuthedLayout />,
        path: 'auth',
        children: [
          { path: 'call', element: <Call /> },
          { path: 'report', element: <RedirectToCall /> },
        ],
      },
    ],
  },
]);

const App = () => {
  const theme = {
    button: {
      styles: {
        base: {
          initial: {
            textTransform: 'none',
          },
        },
        sizes: {
          sm: {
            fontSize: 'text-base',
          },
          md: {
            fontSize: 'text-xl',
          },
        },
      },
    },
    input: {
      defaultProps: {
        size: 'lg',
      },
    },
  };

  return (
    <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY} afterSignOutUrl="/">
      <ThemeProvider value={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ClerkProvider>
  );
};

export default App;
