import { DayPicker } from 'react-day-picker';
import PropTypes from 'prop-types';
import { isBefore, isSameDay } from 'date-fns';
import { cn } from '../lib/utils.js';
import 'react-day-picker/dist/style.css';
import TimezoneSelector from './TimezoneComponent.jsx';

const CalendarView = ({
  className,
  classNames,
  showOutsideDays = true,
  existingCalls = [],
  selectedTimezone,
  refreshTrigger,
  refreshKey,
  onSelect,
  ...props
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const modifiers = {
    past_call: (date) => {
      if (isBefore(date, today)) return false;
      const callOnDate = existingCalls.find((call) => {
        return isSameDay(date, call.scheduledAt) && ['COMPLETED'].includes(call.status);
      });
      return !!callOnDate;
    },
    future_call: (date) => {
      if (isBefore(date, today)) return false;
      const callOnDate = existingCalls.find((call) => {
        return isSameDay(date, call.scheduledAt) && (call.status === 'PENDING' || call.status === 'SCHEDULED');
      });
      return !!callOnDate;
    },
    missed_call: (date) => {
      if (isBefore(date, today)) return false;
      const callOnDate = existingCalls.find((call) => {
        return isSameDay(date, call.scheduledAt) && call.status === 'MISSED';
      });
      return !!callOnDate;
    },
    disabled: (date) => isBefore(date, today), // disable past dates
  };

  const modifiersClassNames = {
    past_call: 'bg-slate-500 text-white hover:bg-white-600 hover:text-slate-900',
    future_call: 'bg-black text-white hover:bg-white-600 hover:text-slate-900',
    missed_call: 'bg-red-500 text-white hover:bg-white-600 hover:text-slate-900',
  };

  // Define an onDayClick handler that always calls the parent's onSelect callback.
  const handleDayClick = (day) => {
    if (onSelect) {
      onSelect(day);
    }
  };

  return (
    <div className={`rounded-lg border p-4 sm:p-8 w-full max-w-xl ${className || ''}`}>
      <DayPicker
        showOutsideDays={showOutsideDays}
        className={cn('p-3 sm:p-6', className)}
        timeZone={selectedTimezone?.value}
        modifiers={modifiers}
        modifiersClassNames={modifiersClassNames}
        onDayClick={handleDayClick}
        classNames={{
          day: 'h-8 w-8 sm:h-12 sm:w-12 text-center p-0 sm:p-2 rounded-full hover:bg-gray-100 text-gray-900',
          selected: 'bg-slate-100 text-black hover:bg-slate-200 hover:text-black focus:bg-slate-400 focus:text-black focus:border-slate-900',
          today:
            'bg-green-600 text-white rounded-full w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center text-center hover:bg-green-500 focus:bg-green-500',
          active:
            'bg-slate-300 text-gray-900 hover:bg-slate-200 hover:text-slate-900 focus:bg-slate-200 focus:text-slate-900 border border-slate-200 rounded-full w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center text-center',
          disabled: 'opacity-50 cursor-not-allowed',
          caption: 'text-sm sm:text-base mb-2',
          head_cell: 'text-xs sm:text-sm text-gray-900 mt-5 bg-red-400',
          table: 'w-full border-collapse space-y-1',
          row: 'flex justify-center space-x-2 w-full',
          cell: 'p-0 relative text-center',
          nav_button: 'h-7 w-7 bg-red-400 p-0 opacity-75 hover:opacity-100 rounded-full border-2 border-transparent hover:border-gray-300',
          nav_button_previous: 'absolute left-1 text-gray-600 bg-red-400 font-bold',
          nav_button_next: 'absolute right-1 text-gray-600 hover:text-gray-900',
        }}
        {...props}
      />
      <div className="mt-4">
        <TimezoneSelector refreshTrigger={refreshTrigger} />
      </div>
    </div>
  );
};

CalendarView.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.object,
  showOutsideDays: PropTypes.bool,
  selectedTimezone: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    offset: PropTypes.string,
  }),
  existingCalls: PropTypes.arrayOf(
    PropTypes.shape({
      scheduledAt: PropTypes.string.isRequired,
      dateInTimezone: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ),
  onTimezoneChange: PropTypes.func,
  refreshTrigger: PropTypes.func,
  refreshKey: PropTypes.number,
  onSelect: PropTypes.func,
};

export default CalendarView;
