import React from 'react';
import { FaClock as ClockIcon, FaShieldAlt as ShieldIcon, FaMicrophone as MicIcon } from 'react-icons/fa';

interface Props {
    userName: string;
    serviceName: string;
}

const WelcomeCard: React.FC<Props> = ({ userName, serviceName }) => {
    return (
        <div className="h-full p-8">
            <div className="space-y-6">
                <div>
                    <div className="w-16 h-16 bg-gradient-to-br from-teal-400 to-blue-500 rounded-full mb-4" />
                    <h1 className="text-xl text-gray-700">Hi, {userName}</h1>
                    <h2 className="text-2xl font-bold text-gray-900">{serviceName}</h2>
                </div>

                <div className="flex items-center gap-2">
                    <ClockIcon className="w-5 h-5 text-blue-300" />
                    <span className="text-lg font-medium">15 min</span>
                </div>

                <p className="text-gray-600">
                    Chat with {serviceName}, your AI companion for mental health.
                </p>

                <div className="space-y-4">
                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                            <MicIcon className="w-4 h-4 text-blue-600" />
                        </div>
                        <span className="text-gray-700">Voice-activated interaction</span>
                    </div>


                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                            <svg className="w-4 h-4 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </div>
                        <span className="text-gray-700">Personalized assessment</span>
                    </div>

                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                            <ShieldIcon className="w-4 h-4 text-blue-600" />
                        </div>
                        <span className="text-gray-700">Confidential and secure</span>
                    </div>

                </div>

                <div className="pt-4">
                    <p className="text-sm text-gray-500">
                        If in crisis, please contact emergency services.
                    </p>
                </div>

                <p className="text-gray-600">
                    Select a time to speak with {serviceName} and take a step towards better mental health.
                </p>
            </div>
        </div>
    );
};

export default WelcomeCard;
