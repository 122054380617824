import { useCallback, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { authGuard } from '../lib/utils.js';
import { useAxios } from '../Components/AxiosProvider.jsx';
import CalendarView from '../Components/CalendarView.jsx';
import WelcomeCard from '../Components/WelcomeCard.tsx';
import UpcomingCalls from '../Components/UpcomingCalls.tsx';
import EventModal from '../Components/EventModal.jsx';
import Toast from '../Components/Toast.jsx';

const Dashboard = () => {
  const auth = authGuard();
  const axios = useAxios();
  const { user } = useUser();

  // Modified timezone state to read from Clerk metadata
  const [selectedTimezone, setSelectedTimezone] = useState(() => {
    const userTimezone = user?.unsafeMetadata?.preferences?.timeZone;
    return userTimezone ? { value: userTimezone, label: userTimezone } : undefined;
  });

  const [date, setDate] = useState(() => {
    const now = new Date();
    return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0));
  });
  const [calls, setCalls] = useState([]);
  const [existingCall, setExistingCall] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: '',
    variant: 'success',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upcomingCalls, setUpcomingCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  const getAllUpcomingCalls = async (days) => {
    const response = await axios.get('/calls/upcoming', {
      params: {
        days,
      },
    });
    setUpcomingCalls(response.data);
  };

  const getAllScheduledCalls = async () => {
    try {
      if (!auth) {
        throw new Error('User not authenticated');
      }
      const response = await axios.get('/calls');
      const callsWithLocalTime = response.data.data.map((call) => {
        const callDate = new Date(call.scheduledAt);
        return {
          ...call,
          scheduledAt: call.scheduledAt,
          localScheduledAt: formatInTimeZone(callDate, selectedTimezone?.value, 'yyyy-MM-dd HH:mm:ss'),
          dateInTimezone: formatInTimeZone(callDate, selectedTimezone?.value, 'yyyy-MM-dd'),
        };
      });

      setCalls(callsWithLocalTime);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching scheduled calls:', error);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        // Load all data in parallel
        await Promise.all([getAllScheduledCalls(), getAllUpcomingCalls(7)]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshCalendar = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleScheduleCall = async (data, timezoneValue) => {
    try {
      if (!auth) {
        throw new Error('User not authenticated');
      }

      if (!date) {
        throw new Error('Date is not selected');
      }

      // Validate timezone
      if (!timezoneValue) {
        throw new Error('Invalid timezone value');
      }

      // Create start Date object in the user's timezone
      const scheduledDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), data.hours, data.minutes, 0);
      // Convert the start date from the user's timezone to UTC
      const utcScheduledAt = toZonedTime(scheduledDate, timezoneValue?.value);
      setIsLoading(true);
      // Make the API call with timezone information
      const response = await axios
        .post('/calls', {
          scheduledAt: utcScheduledAt.toISOString(),
          timezone: timezoneValue?.value,
          type: 'FOLLOWUP',
        })
        .finally(async () => {
          setIsLoading(false);
          await getAllScheduledCalls();
          refreshCalendar();
          setToastMessage({
            message: 'Call scheduled successfully!',
            variant: 'success',
          });
          setShowToast(true);
        });

      return {
        success: true,
        data: response.data,
        scheduledDateTime: utcScheduledAt.toISOString(),
      };
    } catch (error) {
      setToastMessage({
        message: 'Failed to schedule call',
        variant: 'error',
      });
      setShowToast(true);
      throw error;
    }
  };

  const handleCreateRecurringCall = async (data) => {
    // Early validation checks
    if (!auth) {
      setToastMessage({
        message: 'User not authenticated',
        variant: 'error',
      });
      setShowToast(true);
      return;
    }

    const { date: recurringDate, hours, minutes, recurringPattern, editMode, recurringScheduleId } = data;

    if (!recurringDate) {
      setToastMessage({
        message: 'Please select a date',
        variant: 'error',
      });
      setShowToast(true);
      return;
    }

    if (!selectedTimezone?.value) {
      setToastMessage({
        message: 'Please select a timezone',
        variant: 'error',
      });
      setShowToast(true);
      return;
    }

    try {
      // Create start Date object in the user's timezone
      const startDate = new Date(recurringDate.getFullYear(), recurringDate.getMonth(), recurringDate.getDate(), hours, minutes, 0);

      // Convert the start date from the user's timezone to UTC
      const utcStartDate = toZonedTime(startDate, selectedTimezone?.value);

      // Create end date (add 15 minutes and 1 year)
      const tempDate = new Date(startDate.getTime() + 15 * 60 * 1000);
      tempDate.setFullYear(tempDate.getFullYear() + 1);

      // Convert end date to UTC
      const utcEndDate = toZonedTime(tempDate, selectedTimezone?.value);

      // Store in ISO format (which is always in UTC)
      const payload = {
        startDate: utcStartDate.toISOString(),
        endDate: utcEndDate.toISOString(),
        frequency: recurringPattern?.type,
        callType: 'FOLLOWUP',
        editMode: editMode || 'all',
      };
      // Make the API call
      if (data.id) {
        if (editMode === 'all') {
          await axios.patch(`/recurring-calls/${recurringScheduleId}?editMode=${editMode}`, payload);
        } else if (editMode === 'single') {
          await axios.patch(`/recurring-calls/${recurringScheduleId}?editMode=${editMode}&callId=${data.id}`, payload);
        }
      } else {
        await axios.post('/recurring-calls', payload);
      }
      // Update state and notify the user
      await getAllScheduledCalls();
      setToastMessage({
        message: 'Recurring call created successfully!',
        variant: 'success',
      });
      setShowToast(true);
      refreshCalendar();
    } catch (error) {
      // Handle errors gracefully
      setToastMessage({
        message: 'Failed to create recurring call',
        variant: 'error',
      });
      setShowToast(true);
      throw error;
    }
  };

  const handleDeleteEvent = async (event) => {
    try {
      if (!auth) {
        throw new Error('User not authenticated');
      }
      if (event?.recurringSchedule?.id) {
        await axios.delete(`/recurring-calls/${event.recurringSchedule.id}?editMode=${event.editMode}&callId=${event.id}`).then(() => {
          setToastMessage({
            message: 'Recurring call deleted successfully!',
            variant: 'success',
          });
          setShowToast(true);
          getAllScheduledCalls();
          getAllUpcomingCalls(7);
          refreshCalendar();
        });
      } else if (event?.id) {
        await axios.delete(`/calls/${event.id}`).then(() => {
          setToastMessage({
            message: 'Call deleted successfully!',
            variant: 'success',
          });
          setShowToast(true);
          getAllScheduledCalls();
          getAllUpcomingCalls(7);
          refreshCalendar();
        });
      }
    } catch (error) {
      setToastMessage({
        message: 'Failed to delete event',
        variant: 'error',
      });
      setShowToast(true);
    }
  };

  const isSlotHighlighted = useCallback(
    (slotValue) => {
      if (!calls.length) return false;
      return calls.some((call) => {
        const callDate = new Date(call.scheduledAt);
        const callInTimezone = formatInTimeZone(callDate, selectedTimezone.value, 'HH:mm');
        const callDateInTimezone = formatInTimeZone(callDate, selectedTimezone.value, 'yyyy-MM-dd');
        const selectedDateInTimezone = formatInTimeZone(date, selectedTimezone.value, 'yyyy-MM-dd');
        return callDateInTimezone === selectedDateInTimezone && callInTimezone === slotValue;
      });
    },
    [calls, date, selectedTimezone],
  );

  const handleConfirmBooking = async (data) => {
    if (data.callType === 'recurring') {
      await handleCreateRecurringCall(data);
    } else {
      await handleScheduleCall(data, selectedTimezone);
    }
    getAllUpcomingCalls(7);
  };

  const handleDateChange = (newDate) => {
    if (!newDate) return;
    // check if date month is different from current date month throw error
    if (newDate.getMonth() !== new Date().getMonth()) {
      setToastMessage({
        message: 'You can only schedule calls for the current month',
        variant: 'error',
      });
      setShowToast(true);
      return;
    }
    // Set time to noon in UTC to avoid timezone shifts
    const normalizedDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 12, 0, 0));
    setDate(normalizedDate);
    // Open the modal when a calendar date is clicked to schedule a call for the selected date
    setIsModalOpen(true);
  };

  const checkExistingCall = useCallback(
    (selectedDate) => {
      if (!calls.length || !selectedDate) return null;
      // Format selected date in timezone for comparison
      const selectedDateInTimezone = formatInTimeZone(selectedDate, selectedTimezone?.value, 'yyyy-MM-dd');
      return calls.find((call) => call.dateInTimezone === selectedDateInTimezone);
    },
    [calls, selectedTimezone],
  );

  // Check for existing calls when date changes
  useEffect(() => {
    if (date) {
      const existing = checkExistingCall(date);
      if (existing) {
        setExistingCall(existing);
      } else {
        setExistingCall(null);
      }
    }
  }, [date, checkExistingCall]);

  // Update date when timezone changes
  useEffect(() => {
    if (date) {
      const normalizedTimestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0);
      // Only update if the date is not already normalized to avoid re-renders.
      if (date.getTime() !== normalizedTimestamp) {
        setDate(new Date(normalizedTimestamp));
      }
    }
  }, [date, selectedTimezone?.value]);

  // Update useEffect to watch for Clerk metadata changes
  useEffect(() => {
    const userTimezone = user?.unsafeMetadata?.preferences?.timeZone;
    if (userTimezone && userTimezone !== selectedTimezone?.value) {
      setSelectedTimezone({ value: userTimezone, label: userTimezone });
      // Refetch data with new timezone
      getAllScheduledCalls();
      getAllUpcomingCalls(7);
      refreshCalendar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.unsafeMetadata?.preferences?.timeZone]);

  if (isLoading) {
    return (
      <div className="animate-pulse p-4 md:p-8">
        {/* Top row - Hana Callback card */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 mb-4 md:mb-8">
          {/* First Skeleton (Hana Callback UI) */}
          <div className="bg-gray-300 rounded-2xl shadow-lg w-full h-auto min-h-[450px] lg:h-[650px] p-6 flex flex-col space-y-4">
            {/* Profile Circle */}
            <div className="w-14 h-14 rounded-full bg-gray-200"></div>
            {/* Greeting Text */}
            <div className="h-6 bg-gray-200 w-2/3 rounded"></div>
            {/* Title */}
            <div className="h-8 bg-gray-300 w-1/2 rounded"></div>
            {/* Time Icon & Text */}
            <div className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-gray-200 rounded-full"></div>
              <div className="h-4 bg-gray-200 w-1/3 rounded"></div>
            </div>
            {/* Description */}
            <div className="h-4 bg-gray-200 w-5/6 rounded"></div>
            {/* Features */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <div className="w-6 h-6 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 w-2/3 rounded"></div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-6 h-6 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 w-2/3 rounded"></div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-6 h-6 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 w-2/3 rounded"></div>
              </div>
            </div>
            {/* Disclaimer */}
            <div className="h-4 bg-gray-300 w-3/4 rounded"></div>
          </div>
          {/* Second Skeleton (Calendar UI) */}
          <div className="bg-gray-300 rounded-2xl shadow-lg w-full h-auto min-h-[450px] lg:h-[650px] p-6 flex flex-col space-y-6">
            {/* Calendar Header */}
            <div className="flex justify-between items-center">
              <div className="h-6 bg-gray-300 w-1/3 rounded"></div>
              <div className="flex space-x-2">
                <div className="w-6 h-6 bg-gray-200 rounded"></div>
                <div className="w-6 h-6 bg-gray-200 rounded"></div>
              </div>
            </div>
            {/* Calendar Grid */}
            <div className="grid grid-cols-7 gap-2">
              {Array(42)
                .fill('')
                .map((_, i) => (
                  <div key={i} className="h-10 w-10 bg-gray-200 rounded-full"></div>
                ))}
            </div>
            {/* Time Zone Selector */}
            <div className="h-12 bg-gray-200 w-full rounded flex items-center px-4">
              <div className="w-6 h-6 bg-gray-300 rounded"></div>
              <div className="h-4 bg-gray-300 w-2/3 ml-4 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <main className="pt-1 lg:pl-1 transition-all duration-300">
        <div className="p-4 md:p-8">
          {/* Top row with equal width and height cards */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 mb-4 md:mb-8">
            {/* Welcome Card */}
            <div className="flex justify-center">
              <div className="bg-white rounded-2xl shadow-lg hover:border-gray-400 w-full h-auto min-h-[450px] lg:h-[650px] transition-all duration-500 ease-in-out hover:shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
                <WelcomeCard userName={user?.firstName} serviceName="Hana callback" />
              </div>
            </div>
            {/* Calendar Card */}
            <div className="flex justify-center">
              <div className="bg-white rounded-2xl shadow-lg hover:border-gray-400 w-full h-auto min-h-[450px] lg:h-[650px] transition-all duration-500 ease-in-out hover:shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
                <CalendarView
                  mode="single"
                  selected={date}
                  onSelect={handleDateChange}
                  className="rounded-md border-0 w-full"
                  existingCalls={calls}
                  selectedTimezone={selectedTimezone}
                  isSlotHighlighted={isSlotHighlighted}
                  refreshTrigger={refreshCalendar}
                  refreshKey={refreshKey}
                />
              </div>
            </div>
          </div>
          {/* Bottom row with full width upcoming events */}
          <div className="w-full">
            <div className="transition-all duration-500 ease-in-out hover:shadow-[0_8px_30px_rgb(0,0,0,0.12)] shadow-[0_2px_8px_rgb(0,0,0,0.08)]">
              <UpcomingCalls events={upcomingCalls} timezone={selectedTimezone?.value} />
            </div>
          </div>
        </div>
      </main>

      <EventModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          refreshCalendar();
        }}
        selectedDate={date}
        event={existingCall}
        onSave={handleConfirmBooking}
        onDelete={handleDeleteEvent}
        selectedTimezone={selectedTimezone}
        loading={isLoading}
      />
      {showToast && <Toast message={toastMessage.message} variant={toastMessage.variant} setShowToast={setShowToast} />}
    </div>
  );
};

export default Dashboard;
