import { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import Loading from '../Components/Loading.jsx';
import EmailVerification from '../Components/verifyEmail.jsx';
import { Report } from '../Components/IntakeReport.jsx';
import ReportGenerating from '../Components/ReportGenerating.jsx';

const MentalHealthReport = () => {
  const { user } = useUser();
  const { reportId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/report/intake/${reportId}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.status === 500) {
        setError({ message: 'Report not found', status: response.status });
      } else {
        setReportData(await response.json());
      }
    };

    fetchData();
  }, [reportId]);

  const isEmailVerified = user.emailAddresses.some((emailObj) => emailObj.verification.status === 'verified');
  if (!isEmailVerified) {
    return <EmailVerification />;
  }

  if (error?.status === 500) return <ReportGenerating />;
  if (!reportData) return <Loading />;
  return (
    <div className="flex justify-center items-start min-h-screen bg-gray-50 px-4 py-8">
      <Report data={reportData} />
    </div>
  );
};

export default MentalHealthReport;
