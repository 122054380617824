import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button.jsx';
import { Checkbox } from './ui/checkbox.jsx';

const RedirectToCall = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/auth/call');
    }, 1000);
  };

  return (
    <div className="max-w-2xl mx-auto p-6 space-y-8">
      <div className="text-center space-y-4">
        <h1 className="text-3xl font-bold text-gray-900">Thanks for signing up! Are you ready to talk to Hana?</h1>
      </div>

      <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-100">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">General Information</h2>
        <ul className="list-disc pl-5 space-y-3">
          <li className="text-gray-700">It takes 5-7 minutes to do an intake</li>
          <li className="text-gray-700">Hana will take additional 2 minutes to generate the report</li>
        </ul>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-6">
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="privacy" required />
              <label htmlFor="privacy" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                I have read and understood the privacy policy <span className="text-red-600">*</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 ml-6">
              By checking this box, you agree to our{' '}
              <a href="https://www.usehana.com/privacy" target="_blank" className="text-blue-600 underline" rel="noopener noreferrer">
                Privacy Policy
              </a>
              , which outlines how we collect, use, and protect your personal information.
            </p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="microphone" required />
              <label htmlFor="microphone" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                I allow Hana to use my device&apos;s microphone for conversations <span className="text-red-600">*</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 ml-6">
              This permission allows Hana to access your microphone for voice conversations. Your voice data is processed securely and not stored long-term.
            </p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="medical" required />
              <label htmlFor="medical" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                I understand that Hana is not made to substitute medical advice and is not a medical diagnostic tool <span className="text-red-600">*</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 ml-6">
              Hana is designed to provide support and insights, but should not be used as a replacement for professional medical advice, diagnosis, or
              treatment.
            </p>
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <Button type="submit" size="lg" disabled={loading} className="px-8 bg-black text-white">
            {loading ? 'Connecting...' : 'Talk to Hana'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RedirectToCall;
