import { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import TimezoneSelect from 'react-timezone-select';
import { FaSpinner, FaPencilAlt, FaPlus } from 'react-icons/fa';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Toast from './Toast.jsx';

const TimezoneComponent = ({ refreshTrigger }) => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isEditing, setIsEditing] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: '',
    variant: 'success',
  });

  useEffect(() => {
    const fetchUserTimezone = async () => {
      try {
        const timeZone = user?.unsafeMetadata?.preferences?.timeZone;
        if (timeZone) {
          setSelectedTimezone({ value: timeZone, label: timeZone });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch timezone:', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserTimezone();
  }, [user]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const handleTimezoneChange = async (newTimezone) => {
    try {
      if (!newTimezone?.value) {
        return;
      }
      setIsLoading(true);
      await user.update({
        unsafeMetadata: {
          preferences: {
            ...user?.unsafeMetadata?.preferences,
            timeZone: newTimezone?.value,
          },
        },
      });
      setSelectedTimezone(newTimezone);
      setToastMessage({
        message: 'Timezone updated successfully',
        variant: 'success',
      });
      setShowToast(true);
      setIsEditing(false);
      refreshTrigger();
    } catch (error) {
      setToastMessage({
        message: 'Failed to update timezone',
        variant: 'error',
      });
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <span className="text-gray-500 flex items-center">
          <span>Loading...</span>
          <FaSpinner className="animate-spin w-4 h-4 text-gray-500 ml-2" />
        </span>
      </div>
    );
  }

  return (
    <>
      {selectedTimezone?.value && <span className="text-sm font-bold text-gray-600 py-6 my-4">Default Timezone:</span>}
      <div className="flex flex-col items-start min-w-[300px] p-4 rounded-lg border border-gray-200">
        <div className="w-full">
          {isEditing ? (
            <div className="max-w-md w-full">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                className="w-full"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: '#FFFFFF',
                    borderRadius: '0px',
                    borderWidth: '0px',
                    boxShadow: 'none',
                  }),
                }}
              />
            </div>
          ) : (
            <div className="flex justify-between items-center w-full">
              {selectedTimezone?.value ? (
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <span className="font-medium text-gray-900">{selectedTimezone.label}</span>
                    <button onClick={() => setIsEditing(true)} className="p-1 hover:bg-gray-100 rounded-full transition-colors">
                      <FaPencilAlt className="w-4 h-4 text-gray-500" />
                    </button>
                  </div>
                  <div className="mt-1">
                    <span className="text-gray-500">Current Time: </span>
                    <span className="text-gray-700 font-medium">{format(toZonedTime(currentTime, selectedTimezone.value), 'h:mma')}</span>
                  </div>
                </div>
              ) : (
                <button onClick={() => setIsEditing(true)} className="flex items-center space-x-2 p-1 hover:bg-gray-100 rounded-full transition-colors">
                  <FaPlus className="w-4 h-4 text-gray-500" />
                  <span className="text-gray-700 font-medium">Select Time Zone</span>
                </button>
              )}
            </div>
          )}
        </div>
        {showToast && <Toast message={toastMessage.message} variant={toastMessage.variant} setShowToast={setShowToast} />}
      </div>
    </>
  );
};

TimezoneComponent.propTypes = {
  refreshTrigger: PropTypes.func.isRequired,
};

export default TimezoneComponent;
